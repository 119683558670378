import gameConfig from '@content/gameconfig';
import { v4 as uuidv4 } from 'uuid';

import { encrypt } from './caeserCipher';
import { setLocalStoreUserId, getLocalStoreUserId } from './localStore';
import { getCurrentTimers } from './navigationTimers';

let cachedSessionObject: SessionData | null = null;
const consoleEnabled = process.env.GATSBY_PIXEL_CONSOLE_ENABLED === 'true';

function getPixelEndpoint() {
  const endpointOverride = process.env.GATSBY_PIXEL_FULL_ENDPOINT;
  if (endpointOverride != null && endpointOverride !== '') {
    return endpointOverride;
  }

  return `${process.env.GATSBY_PIXEL_HOST || ''}/pixel`;
}

function isPixelActive() {
  const isPixelActiveFlag = process.env.GATSBY_IS_PIXEL_ACTIVE;
  console.log(isPixelActiveFlag);
  if (isPixelActiveFlag === 'FALSE') {
    return false;
  }
  return true;
}

const pixelTracker = async ({
  collectionName,
  data,
}: {
  collectionName:
  | 'sessions'
  | 'answers'
  | 'corrections'
  | 'demographics'
  | 'events';
  data: Omit<LogData, 'collection_name'> | Omit<SessionData, 'collection_name'>;
}) => {
  if (isPixelActive() === false) {
    return;
  }
  const parameterizedData = Object.entries(data)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value.toString())}`,
    )
    .join('&');

  try {
    const response = await fetch(
      `${getPixelEndpoint()}?cn=${collectionName}&${encrypt(
        parameterizedData,
      )}`,
      { mode: 'no-cors' },
    );
    // Status is zero when no-cors is used. Once we kill no-cors we should kill
    // this status check.
    if (response.status !== 200 && response.status !== 0) {
      console.warn(
        `Pixel request returned unexpected status code ${response.status}`,
      );
    }
  } catch (error) {
    console.error('Pixel request error:', error);
  }
};

export type SessionData = {
  // this id is only relevant with pixels, as it is part of the payload itself
  // it is transient
  session_id: string;
  // this id is stored locally to track repeats
  user_id: string;
  host: string;
  language: string;
  survey_name: string;
  // basically the hash, but internally we call this 'version'
  version: string;
  // to identify which code release we are on
  core_version: string;
  // this is subject to change as game continues (provided we are using profiler)
  stream_name: string;
  referrer: string;
  is_pair_share_session: boolean;
};

export type OptionalSessionData = Partial<SessionData>;
export type SessionInitResponse = {
  sessionId: string;
  userId: string;
};

/** initialize a unique session */
export const initSession = async (
  optSessionData: OptionalSessionData = {},
  // this is when we are using the pixel tracker and want to perform an update
  optSessionId: string | null = null,
): Promise<SessionInitResponse> => {
  // we need to first make a unique player id here if not already one in local store
  const userId = (() => {
    const localUserId = getLocalStoreUserId();
    if (localUserId) {
      return localUserId;
    }
    const newUserId = uuidv4();
    // we must this user id in local for retrieval in case user resets
    setLocalStoreUserId(newUserId);
    return newUserId;
  })();
  console.log('initSession -> existing userId', userId);
  console.log('initSession -> override userId', optSessionData.user_id);

  const streamTableNames = gameConfig.Stream_Table_Names;
  const refObj: SessionData = cachedSessionObject
    ? {
      ...cachedSessionObject,
      user_id: userId,
      is_pair_share_session: false,
      ...optSessionData,
    }
    : {
      user_id: userId,
      host: window?.location?.host,
      language:
        gameConfig.Languages_Used?.[0]?.Content_Type ||
        gameConfig.Languages_Used?.Content_Type,
      survey_name: gameConfig.Version,
      version: window.location.hash.replace('#', ''),
      core_version: `${gameConfig.coreVersion}${process.env.BRANCH ? `-${process.env.BRANCH}` : ''
        }`,
      referrer: document?.referrer || '',
      stream_name: Array.isArray(streamTableNames)
        ? streamTableNames[0]
        : streamTableNames,
      is_pair_share_session: false,
      ...optSessionData,
    };
  cachedSessionObject = refObj;
  let sessionId = optSessionId;

  // if we already have a sessionId passed, no need to create a new one
  if (!sessionId) {
    sessionId = uuidv4();
  }

  // Fire and forget.
  pixelTracker({
    collectionName: 'sessions',
    data: {
      ...refObj,
      session_id: sessionId,
    },
  });

  if (consoleEnabled === true) {
    console.info(
      '%c 🔎 For transparency, you can see all the data that we log below 🔎 ',
      'color: red',
    );
  }
  return { sessionId, userId: optSessionData.user_id || refObj.user_id };
};

/** each entry in our db has these in common */
export interface CommonLogData {
  // this is optional, and is the name of the content type entry on airtable
  question_name?: string;
  // question_index: number;
  step_counter?: number;
}

export interface Answers {
  /* text is weird, we can say, this is more like the value (TODO: change name later) */
  answer_text: string;
  duration_in_seconds: number;
  // effectively the name that is supplied on airtable, it contains the type in there as well
  question_type: string;
  // the last option here is for when you are using this interface in claims, claims have no results
  result: 'correct' | 'incorrect' | '';
  collection_name: 'answers';
}

interface Correction {
  comments: string;
  source_url: string;
  collection_name: 'corrections';
}

interface Demographic {
  /** this can be an array as well because demo questions can have multiple options */
  answers: string[] | object;
  collection_name: 'demographics';
}

interface Event {
  event_type:
    | 'click'
    | 'open_context_menu'
    | 'toggle_lang'
    | 'set_stream'
    | 'toggle_settings'
    | 'session_kicked'
    | 'rank_up'
    | 'rank_down'
    | 'consent'
    | 'reset_game'
    | 'finished_game'
    | 'error'
    | 'init_performance'
    | 'navigate'
    | 'ping';
  location?: string;
  /** a little more specific than just the question name, can be descriptive such as "help button" etc. */
  target: string | number;
  collection_name: 'events';
}

export type LogData = Answers | Correction | Demographic | Event | Timing;

export const log = async (
  data: LogData & CommonLogData,
  sessionId: string | null,
): Promise<void> => {
  if (!sessionId) {
    console.error('attempting to log item without a session id', data);
  }
  /** NOTE: why is this in prod? This represents transparency. Anyone can know what we intake.
   * This transparency is similar to seeing your debug log on a variety of apps and services
   */
  if (consoleEnabled === true) {
    console.info('LOG: ', data);
  }
  const { collection_name, ...dataMinusCollectionName } = data;

  await pixelTracker({
    collectionName: collection_name,
    data: {
      ...dataMinusCollectionName,
      session_id: sessionId || 'error',
    },
  });
};

export const batchLog = async (
  data: LogData[] & CommonLogData[],
  sessionId: string | null,
): Promise<void> => {
  if (!sessionId) {
    console.error('attempting to log item without a session id', data);
  }
  let promiseBatch: Promise<any>[] = [];
  data.map(datum => {
    const { collection_name, ...datumMinusCollectionName } = datum;
    if (consoleEnabled === true) {
      console.info('LOG: ', datumMinusCollectionName);
    }
    promiseBatch = [
      ...promiseBatch,
      pixelTracker({
        collectionName: collection_name,
        data: {
          ...datumMinusCollectionName,
          session_id: sessionId || 'error',
        },
      }),
    ];
  });
  await Promise.all(promiseBatch);
};

export async function logEventNavTiming(
  sessionId: string,
  counter: number,
): Promise<void> {
  await log(
    {
      event_type: 'init_performance',
      collection_name: 'timing',
      step_counter: counter,
      version: window.location.hash.replace('#', ''),
      ...(await getCurrentTimers()),
    },
    sessionId,
  ).catch(error => {
    console.error('Failed to log timers.');
    console.error(error);
  });
}

export const setLanguage = async (
  language: string,
  sessionId: string | null,
) => {
  // we cannot "update" our documents per-se, so we just create a new session obj
  await initSession(
    {
      ...cachedSessionObject,
      language,
    },
    sessionId,
  );
};

export const setStreamName = async (
  streamName: string,
  sessionId: string | null,
) => {
  await initSession(
    {
      ...cachedSessionObject,
      stream_name: streamName,
    },
    sessionId,
  );
};
